<template>
	<div @dblclick="dblClickHandler" class="modal-overlay active">
		<div @click.stop="" class="modal">
			<span @click="toggleModal" class="close" title="Fechar campo">
				<icone-fechar />
			</span>
						
			<div id="form">
				<h2 id="add_title">Editar demanda</h2>
        <edit-autor
          v-if="demandaAutor"
          :demanda="demanda"
          @edittedDemandaComp="passDemanda"
          @toggleEditDemanda="toggleModal"
        />
        <edit-responsavel
          v-else
          :demanda="demanda"
          @edittedDemandaComp="passDemanda"
          @toggleEditDemanda="toggleModal"
        />
			</div>
		</div>
	</div>
</template>

<script>
import IconeFechar from '@/assets/SVGs/iconeFechar.vue'
import EditResponsavel from './EditResponsavel.vue'
import EditAutor from './EditAutor.vue'

export default {
  components: {
    'icone-fechar': IconeFechar,
    'edit-responsavel': EditResponsavel,
    'edit-autor': EditAutor,
  },
  props: {
    demanda: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleModal() {
      this.$emit('toggleEditDemanda', { target: 'editDemanda' })
    },
    dblClickHandler(e) {
      if(e.target.className === 'modal-overlay active')
        this.toggleModal()
    },
    passDemanda({ alteracoes, metrica_criada }) {
      this.$emit('edittedDemanda', { alteracoes, metrica_criada })
    },
  },
  computed: {
    demandaAutor() {
      return this.demanda.autor._id == this.$store.state.user.id
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../Modals.scss';
@import './ModalDemandas.scss';
</style>