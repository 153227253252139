<template>
  <div v-if="!loaded">
    <p>Carregando...</p>
  </div>
  <div v-else style="margin: .5rem 0">
    <h4>Setor</h4>
    <div style="margin-bottom: 10px">
      <div class="checklabel destaque todos">
        <label class="container_check usuarios">
          <input type="checkbox" name="" id="todos_select" class="input_check"
            :indeterminate="usersPacial" value="Todos"
            :checked="ids_selecionados.length == usuarios.length"
            @change="selectSetor"
          >
          <span class="checkmark usuarios"></span>
        </label>
        <label @click="setores_exibidos=[]" title="Limpar seleção de setores">Todos</label>
      </div>
      <div class="list_nice_separation">
        <div class="checklabel destaque setor" v-for="setor in setores" 
          :key="setor.nome"
          :class="{ setor_selecionado: setores_exibidos.includes(setor.nome) }"
          :style="{ background: setoresColors[setor.nome] }"
        >
          <label class="container_check usuarios">
            <input type="checkbox" name="" class="input_check"
              :id="setor.nome.replaceAll(' ', '_') + 'Check'"
              :value="setor.nome"
              @change="selectSetor"
            >
            <input type="checkbox" name="" 
              :id="setor.nome+'Exibir'"
              :value="setor.nome"
              v-model="setores_exibidos"
              style="display: none"
            >
            <span class="checkmark usuarios"></span>
          </label>
          <label :for="setor.nome+'Exibir'" title="Exibir setor" class="nome_label">
            {{setor.nome}}
          </label>
        </div>
      </div>
    </div>
    <h4>Usuários</h4>
    <div id="lista_usuarios" class="list_nice_separation">
      <div
        class="seletor_usuario checklabel"
        v-for="usuario in usuariosSetor" 
        :title="'Selecionar '+usuario.nome"
        :key="usuario._id"
      >
        <label class="container_check usuarios">
          <input type="checkbox" name="" class="input_check"
            v-model="ids_selecionados"
            :id="usuario._id+'User'" 
            :value="usuario._id"
            @change="selectUsuario"
          >
          <span class="checkmark usuarios"></span>
        </label>

        <label :for="usuario._id+'User'" class="nome_label">{{usuario.nome}}</label>
      </div>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService"
import handleError from '@/mixins/handleError'

import setoresColors from '@/utils/colors'

export default {
  props: {
    preselecionados: {
      type: Array,
      required: false
    },
    com_user: {
      type: Boolean,
      required: false
    },
    sem_autor: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      loaded: false,
      setores_exibidos: [],
      setores: [],
      usuarios: [],
      ids_selecionados: [],
      setoresColors
    }
  },
  mixins: [handleError],
  async mounted() {
    try {
      const response = await EventService.fetchSetores()
      const setores_sem_user_unico = response.usuarios.filter((setor) => {
        const setor_unico = setor.usuarios.length == 1
        const setor_user = this.$store.state.user.setor == setor.nome
        return !(setor_unico && setor_user)
      })
      this.setores = setores_sem_user_unico

      this.setores.forEach(setor => {
        this.usuarios = this.usuarios.concat(setor.usuarios)
      })
      this.usuarios = this.usuarios.sort((a, b) => {
        if(a.nome.toUpperCase() < b.nome.toUpperCase()) return -1
        if(a.nome.toUpperCase() > b.nome.toUpperCase()) return 1
        return 0
      })
      this.usuarios = this.usuarios.filter(({ permissoes, _id }) => {
        const permissao = permissoes.demandas 
        const user = (this.$store.state.user.id != _id) || this.com_user
        const autor = !(this.sem_autor == _id)

        return permissao && user && autor
      });


      this.setores = this.setores.sort((a, b) => {
        if(a.nome < b.nome) return -1
        if(a.nome > b.nome) return 1
        return 0
      })

      if(this.preselecionados){
        let preselecionados_filtrados

        if(typeof this.preselecionados[0] == 'string'){
          preselecionados_filtrados = this.preselecionados.filter(_id => {
            const user = (this.$store.state.user.id != _id) || this.com_user
            const autor = !(this.sem_autor == _id)

            return user && autor
          })
        }
        else if(this.preselecionados[0]?._id) {
          preselecionados_filtrados = this.preselecionados.filter(({_id}) => {
            const user = (this.$store.state.user.id != _id) || this.com_user
            const autor = !(this.sem_autor == _id)

            return user && autor
          })
        }

        if(preselecionados_filtrados.length > 0){
          const users_preselecionados = preselecionados_filtrados
          const ids_preselecionados = users_preselecionados.map(({_id}) => _id )
          this.ids_selecionados = ids_preselecionados
          ids_preselecionados.forEach((id) => {
            setTimeout(() => {
              this.selectUsuario({ target: { value: id } })
            }, 50)
          })
        }
      }

      this.loaded = true
    } catch(e) {
      this.loaded = true
      handleError(e)
    }
  },
  methods: {
    selectSetor(event) {
      const { checked, value } = event.target
      
      if(checked && value == 'Todos')
        this.ids_selecionados = this.usuarios.map(({ _id }) => _id)
      else if (!checked && value == 'Todos')
        this.ids_selecionados = []

      if(value == 'Todos'){
        this.setores.forEach(({ nome }) => {
          document.querySelector(`#${nome.replaceAll(' ', '_')}Check`).checked = checked
          document.querySelector(`#${nome.replaceAll(' ', '_')}Check`).indeterminate = false
        })
        this.$emit('seletorChange', { usuariosSelecionados: this.ids_selecionados })
        return
      }
      
      const usuarios_setor = this.usuarios.filter(({ setor }) => setor == value)
      const ids_setor = usuarios_setor.map(({ _id }) => _id)

      this.ids_selecionados = this.ids_selecionados.filter(e => !ids_setor.includes(e))
      if(checked){
        this.ids_selecionados = this.ids_selecionados.concat(ids_setor)
        return this.$emit('seletorChange', { usuariosSelecionados: this.ids_selecionados })
      }

      this.$emit('seletorChange', { usuariosSelecionados: this.ids_selecionados })
    },
    selectUsuario(event) {
      const { value } = event.target
      
      const setor_user = this.usuarios.filter(({ _id }) => value == _id)[0].setor
      const usuarios_setor = this.usuarios.filter(({ setor }) => setor == setor_user)
      const ids_setor = usuarios_setor.map(({ _id }) => _id)
      const ids_setor_selecionados = this.ids_selecionados.filter(id => ids_setor.includes(id))
      const check_setor = document.querySelector(`#${setor_user.replaceAll(' ', '_')}Check`)
      check_setor.indeterminate = false
      check_setor.checked = false

      if(ids_setor_selecionados.every(id => ids_setor.indexOf(id) !== -1 ) &&
        ids_setor_selecionados.length > 0) 
      {
        check_setor.indeterminate = true
        check_setor.checked = false

        if(ids_setor.length == ids_setor_selecionados.length){
          check_setor.checked = true
          check_setor.indeterminate = false
        }
      }
      this.$emit('seletorChange', { usuariosSelecionados: this.ids_selecionados })
    },
  },
  computed: {
    usuariosSetor() {
      return this.usuarios.filter(usuario => 
        this.setores_exibidos.includes(usuario.setor) || this.setores_exibidos.length == 0
      )
    },
    usersPacial(){
      return this.ids_selecionados.length > 0 && this.ids_selecionados.length < this.usuarios.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'Usuarios.scss';
</style>