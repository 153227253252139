<template>
  <form @submit.prevent="validate">
    <div class="input-group">
      <h3 class="sr-only" for="descricao">Descrição</h3>
      <textarea required autocomplete="off" 
        id="add_descricao" name="descricao" placeholder="Descrição da demanda"
        v-model="modalData.descricao"
      >
      </textarea>
    </div>

    <div class="input-group">
      <h3 class="sr-only" for="descricao">Responsáveis</h3>
      <seletor-usuarios 
        :preselecionados="demanda.responsaveis"
        :com_user="false"
        :sem_autor="demanda.autor._id"
        @seletorChange="selectResponsaveis"
      />
    </div>

    <div class="input-group">
      <h3 class="sr-only" for="descricao">Status</h3>
      <select name="status" id="add_status" required
        v-model="modalData.status"
      >
        <option value="Abertura">Abertura</option>
        <option value="Em andamento">Em andamento</option>
        <option value="Concluída">Concluída</option>
      </select>
    </div>

    <div class="input-group actions">
      <button type="button" @click="toggleModal" class="button cancel clear_button">Cancelar</button>
      <button class="button new">Editar</button>
    </div>
  </form>
  
</template>

<script>
import EventService from '@/services/EventService'
import handleError from '@/mixins/handleError'

import selectorUsuarios from '@/components/Seletor/Usuarios.vue'

export default {
  components:{
    'seletor-usuarios': selectorUsuarios,
  },
  props: {
    demanda: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      modalData: {
        descricao: '',
        status: '',
        responsaveis: [],
      }
    }
  },
  mounted() {
    this.modalData.descricao = this.demanda.descricao
    this.modalData.status = this.demanda.status
    this.modalData.responsaveis = this.demanda.responsaveis
  },
  methods: {
    toggleModal() {
      this.$emit('toggleEditDemanda')
    },
    selectResponsaveis({ usuariosSelecionados }) {
			this.modalData.responsaveis = usuariosSelecionados
      if(this.modalData.responsaveis.length == 1 && this.modalData.responsaveis[0]._id) {
        this.modalData.responsaveis = [this.modalData.responsaveis[0]._id]
      }
      else if (this.modalData.responsaveis.length == 0) {
        this.modalData.responsaveis = [this.$store.state.user.id]
      }
		},
    validate() {
      if(this.modalData.responsaveis.length < 1) return this.$Swal.fire('Selecione pelo menos 1 responsável!')

      this.submit()
    },
    async submit() {
      // this.modalData.responsaveis.push(this.$store.state.user.id)
      let pacote = {
        id: this.demanda._id,
        ...this.modalData,
      }

      this.$store.dispatch('loading')

      EventService.editDemanda(pacote)
      .then(({ alteracoes, metrica_criada }) => {
        this.$emit('edittedDemandaComp', { alteracoes, metrica_criada })
		    this.$store.dispatch('notLoading')
        this.toggleModal()
        
        this.$Swal.fire({
            title: "Demanda editada!",
            icon: "success"
        })
      })
      .catch(handleError)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../Modals.scss';
@import 'ModalDemandas.scss';
</style>